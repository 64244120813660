
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  format(e) {
    let cleaned = "" + e.currentTarget.value
    if (cleaned.startsWith('+1')) {
      cleaned = cleaned.substring(3)
    }
    cleaned = cleaned.replace(/\D/g, '');

    let areaCode = cleaned.substring(0, 3);
    let firstPart = cleaned.substring(3, 6);
    let secondPart = cleaned.substring(6, 10);
    if (areaCode) {
      let formattedPhone = ''
      if (areaCode.length === 0) {
        formattedPhone = ''
      } else if (areaCode.length < 3) {
        formattedPhone += areaCode
      } else if (areaCode.length === 3 && firstPart.length === 0) {
        formattedPhone += areaCode
      } else {
        formattedPhone += areaCode + ' ' + [firstPart, secondPart].filter(i => i).join("-")
      }

      e.currentTarget.value = "+1 " + formattedPhone
    };
  }

  connect() {
    this.format({ currentTarget: this.element })
    this.element.dataset.action = "input->phone-formatter#format"
  }
}