// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>


// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.glob('./**/*_channel.js', {eager: true})

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "@hotwired/turbo-rails"

import { Application, defaultSchema } from "@hotwired/stimulus"
import { registerControllers } from 'stimulus-vite-helpers'
import { Alert, Dropdown, Tabs } from "tailwindcss-stimulus-components"

const customSchema = {
  ...defaultSchema,
  keyMappings: { ...defaultSchema.keyMappings, slash: "/" },
}
const application = Application.start(document.documentElement, customSchema)

const isDebug = window.location.hostname.includes("localhost")

// Configure Stimulus development experience
window.Stimulus = application
application.debug = isDebug

const controllers = import.meta.glob(['../controllers/*_controller.js', '../../components/*_controller.js'], { eager: true })
registerControllers(application, controllers)
window.Stimulus.register("alert", Alert)
window.Stimulus.register("dropdown", Dropdown)
window.Stimulus.register("tabs", Tabs)

const confirmMethod = (title, element) => {
  return new Promise((resolve) => {
    const dialogTemplate = document.querySelector(
      "template#confirm_template"
    );
    const temp = document.importNode(dialogTemplate.content, true);
    const dialog = temp.querySelector("dialog");
    const destructiveButton = dialog.querySelector("[data-destructive=true]")
    const defaultButton = dialog.querySelector("[data-default=true]")
    if (element.dataset.style === "destructive") {
      defaultButton.remove()
    } else {
      destructiveButton.remove()
    }

    dialog.dataset.confirmDialogTitleValue = title || "Confirm";
    dialog.dataset.confirmDialogBodyValue =
      element.dataset.body || "Are you sure?";

    dialog.dataset.confirmDialogButtonValue = element.dataset.button || "OK";


    dialog.addEventListener("close", () => {
      resolve(dialog?.returnValue === "confirm");
    });
    document.body.appendChild(temp);
  });
};

window.confirmMethod = confirmMethod;
window.Turbo.setConfirmMethod(confirmMethod);
