import { Controller } from "@hotwired/stimulus"

function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

export default class extends Controller {
  static values = {
    events: { type: Array, default: ["change", "input"] }
  }

  submit = debounce((e) => {
    this.element.requestSubmit();
  }, 100)

  connect = () => {
    this.element.dataset.action = this.eventsValue.map(e => `${e}->submit-on-change#submit`).join(" ")
  }
}
