import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["chart"];
  static values = { json: Array };

  init() {
    return new Promise((resolve, reject) => {
      if (window.ApexCharts) { return resolve() }

      const script = document.createElement('script')
      script.src = "https://cdn.jsdelivr.net/npm/apexcharts"
      script.onerror = reject
      script.onload = () => {
        resolve()
      }
      document.head.appendChild(script)
    })
  }

  connect() {
    this.init().then(this.loadChart)
  }

  loadChart = () => {
    let element = this.chartTarget
    let myChart = new ApexCharts(element, {
      title: {
        text: '',
        align: 'left'
      },
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        }

      },

      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },

      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 90, 100]
        },
      },
      stroke: {
        curve: 'smooth',
      },

      series: [{
        name: "Leads",
        data: this.jsonValue
      }],
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          formatter: function (val, loa) {
            return val.toFixed(0);
          },
        },
        format: function (val) {
          return val.toFixed(0);
        },
        title: {
          text: ''
        },
        opposite: true,
      }
    })
    myChart.render();
  }
}